import styled from "styled-components"
import { useContext, useEffect, useState } from "react"
import { DataContext } from "../Context/DataContext"
import HashLoader from "react-spinners/HashLoader";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title } from 'chart.js';
import { BasicTable, AccountTable, AccountTable1 } from "../Components/BasicTable";


ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

const Container = styled.div`
    height: 100%;
    width: 100%;
    // border: 1px solid green;
`

const Loading = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    text-decoration: underline;
    color: var(--black);
`

const PieContainer = styled.div`
      Height: 100%;
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
`
const PieChart = styled.div`
      Height: 250px;
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: rgba(100, 100, 111, 0.1);
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`

const SalesvsReturn = ({ setSalevsReturn }) => {

    const { setSelectedSite, allData } = useContext(DataContext)

    const [finalData, setFinalData] = useState([])


    useEffect(() => {

        var result = [];
        allData.reduce(function (res, value) {
            if (!res[value['Party Category']]) {
                res[value['Party Category']] = { Id: value['Party Category'], 'Sales Value': 0, 'Sales Return (Value)': 0, 'Sales Qty': 0, 'Sales Return Qty': 0, 'Sales Qty (Kgs)': 0, 'Sales Return (Kgs)': 0 };
                result.push(res[value['Party Category']])
            }
            res[value['Party Category']]['Sales Value'] += Math.round(value['Sales Value']);
            res[value['Party Category']]['Sales Return (Value)'] += Math.round(value['Sales Return (Value)']);
            res[value['Party Category']]['Sales Qty'] += Math.round(value['Sales Qty']);
            res[value['Party Category']]['Sales Return Qty'] += Math.round(value['Sales Return Qty']);
            res[value['Party Category']]['Sales Qty (Kgs)'] += Math.round(value['Sales Qty (Kgs)']);
            res[value['Party Category']]['Sales Return (Kgs)'] += Math.round(value['Sales Return (Kgs)']);
            return res;
        }, {});

        result.sort((a, b) => (a['Sales Return (Value)'] < b['Sales Return (Value)']) ? -1 : ((b['Sales Return (Value)'] > a['Sales Return (Value)']) ? 1 : 0))
        setFinalData(result)
        setSalevsReturn(result)

    }, [allData])


    return (
        <Container>
            {allData.length > 0 ?
                <>
                    <AccountTable finalData={finalData} selectable={false} />
                </>
                :
                <Loading>
                    <HashLoader size={28} />
                </Loading>
            }
        </Container >
    )
}

const SalesvsReturnChart = () => {

    const { allData, chartColors, selectedSite } = useContext(DataContext)


    const options = {
        plugins: {
            legend: {
                display: true
            }
        },
        // indexAxis: 'y',

        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                border: {
                    display: false
                },
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return this.getLabelForValue(val).substring(0, 12) + "...";
                    },
                    crossAlign: 'far',
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    }
                }
            },
            y: {
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return index % 2 === 0 ? this.getLabelForValue(val) : "";
                    },
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    },
                }
            }
        }

    }

    const [chartData, setChartData] = useState({
        datasets: [],
    });

    useEffect(() => {

        var result = [];
        allData.reduce(function (res, value) {
            if (!res[value['Party Category']]) {
                res[value['Party Category']] = { Id: value['Party Category'], 'Sales Value': 0, 'Sales Return (Value)': 0, 'Sales Qty': 0, 'Sales Return Qty': 0, 'Sales Qty (Kgs)': 0, 'Sales Return (Kgs)': 0 };
                result.push(res[value['Party Category']])
            }
            res[value['Party Category']]['Sales Value'] += Math.round(value['Sales Value']);
            res[value['Party Category']]['Sales Return (Value)'] += Math.round(value['Sales Return (Value)']);
            res[value['Party Category']]['Sales Qty'] += Math.round(value['Sales Qty']);
            res[value['Party Category']]['Sales Return Qty'] += Math.round(value['Sales Return Qty']);
            res[value['Party Category']]['Sales Qty (Kgs)'] += Math.round(value['Sales Qty (Kgs)']);
            res[value['Party Category']]['Sales Return (Kgs)'] += Math.round(value['Sales Return (Kgs)']);
            return res;
        }, {});

        result.sort((a, b) => (a['Sales Return (Value)'] < b['Sales Return (Value)']) ? -1 : ((b['Sales Return (Value)'] > a['Sales Return (Value)']) ? 1 : 0))



        setChartData({
            labels: result.map((data) => data['Id']),
            datasets: [{
                label: "Sales",
                data: result.map((data) => data['Sales Value']),
                backgroundColor: chartColors[9],
                borderWidth: 0
            },
            {
                label: "Return",
                data: result.map((data) => data['Sales Return (Value)']),
                backgroundColor: chartColors[0],
                borderWidth: 0
            }]
        });

    }, [allData, selectedSite])


    return (
        <Container>
            {allData.length > 0 ?
                <Chart type='bar' options={options} data={chartData}
                />
                :
                <Loading>
                    <HashLoader size={28} />
                </Loading>
            }
        </Container>

    )
}


const SalesvsPendingvsPOLoss = ({ setSalesvsPendingvsPOLoss }) => {

    const { setSelectedSite, allData } = useContext(DataContext)

    const [finalData, setFinalData] = useState([])


    useEffect(() => {

        var result = [];
        allData.reduce(function (res, value) {
            if (!res[value['Party Category']]) {
                res[value['Party Category']] = { Id: value['Party Category'], 'Sales Value': 0, 'Sales Return (Value)': 0, 'Sales Qty': 0, 'Sales Return Qty': 0, 'Sales Qty (Kgs)': 0, 'Sales Return (Kgs)': 0, 'Pending Orders (Value)': 0, 'PO Lost (Value)': 0, 'Pending Orders (Qty)': 0, 'PO Lost (Qty)': 0, 'Pending Orders (Qty-Kgs)': 0, 'PO Lost (Kgs)': 0 };
                result.push(res[value['Party Category']])
            }
            res[value['Party Category']]['Sales Value'] += Math.round(value['Sales Value']);
            res[value['Party Category']]['Sales Return (Value)'] += Math.round(value['Sales Return (Value)']);
            res[value['Party Category']]['Pending Orders (Value)'] += Math.round(value['Pending Orders (Value)']);
            res[value['Party Category']]['PO Lost (Value)'] += Math.round(value['PO Lost (Value)']);

            res[value['Party Category']]['Sales Qty'] += Math.round(value['Sales Qty']);
            res[value['Party Category']]['Sales Return Qty'] += Math.round(value['Sales Return Qty']);
            res[value['Party Category']]['Pending Orders (Qty)'] += Math.round(value['Pending Orders (Qty)']);
            res[value['Party Category']]['PO Lost (Qty)'] += Math.round(value['PO Lost (Qty)']);

            res[value['Party Category']]['Sales Qty (Kgs)'] += Math.round(value['Sales Qty (Kgs)']);
            res[value['Party Category']]['Sales Return (Kgs)'] += Math.round(value['Sales Return (Kgs)']);
            res[value['Party Category']]['Pending Orders (Qty-Kgs)'] += Math.round(value['Pending Orders (Qty-Kgs)']);
            res[value['Party Category']]['PO Lost (Kgs)'] += Math.round(value['PO Lost (Kgs)']);
            return res;
        }, {});

        setFinalData(result)
        setSalesvsPendingvsPOLoss(result)

    }, [allData])


    return (
        <Container>
            {allData.length > 0 ?
                <>
                    <AccountTable1 finalData={finalData} selectable={false} />
                </>
                :
                <Loading>
                    <HashLoader size={28} />
                </Loading>
            }
        </Container >
    )
}



const SalesvsPendingvsPOLossChart = () => {

    const { allData, chartColors, selectedSite } = useContext(DataContext)


    const options = {
        plugins: {
            legend: {
                display: true
            }
        },
        // indexAxis: 'y',

        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false
                },
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return this.getLabelForValue(val).substring(0, 12) + "...";
                    },
                    crossAlign: 'far',
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    }
                }
            },
            y: {
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return index % 2 === 0 ? this.getLabelForValue(val) : "";
                    },
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    },
                }
            }
        }

    }

    const [chartData, setChartData] = useState({
        datasets: [],
    });

    useEffect(() => {

        var result = [];
        allData.reduce(function (res, value) {
            if (!res[value['Party Category']]) {
                res[value['Party Category']] = { Id: value['Party Category'], 'Sales Value': 0, 'Sales Return (Value)': 0, 'Sales Qty': 0, 'Sales Return Qty': 0, 'Sales Qty (Kgs)': 0, 'Sales Return (Kgs)': 0, 'Pending Orders (Value)': 0, 'PO Lost (Value)': 0, 'Pending Orders (Qty)': 0, 'PO Lost (Qty)': 0, 'Pending Orders (Qty-Kgs)': 0, 'PO Lost (Kgs)': 0 };
                result.push(res[value['Party Category']])
            }
            res[value['Party Category']]['Sales Value'] += Math.round(value['Sales Value']);
            res[value['Party Category']]['Sales Return (Value)'] += Math.round(value['Sales Return (Value)']);
            res[value['Party Category']]['Pending Orders (Value)'] += Math.round(value['Pending Orders (Value)']);
            res[value['Party Category']]['PO Lost (Value)'] += Math.round(value['PO Lost (Value)']);

            res[value['Party Category']]['Sales Qty'] += Math.round(value['Sales Qty']);
            res[value['Party Category']]['Sales Return Qty'] += Math.round(value['Sales Return Qty']);
            res[value['Party Category']]['Pending Orders (Qty)'] += Math.round(value['Pending Orders (Qty)']);
            res[value['Party Category']]['PO Lost (Qty)'] += Math.round(value['PO Lost (Qty)']);

            res[value['Party Category']]['Sales Qty (Kgs)'] += Math.round(value['Sales Qty (Kgs)']);
            res[value['Party Category']]['Sales Return (Kgs)'] += Math.round(value['Sales Return (Kgs)']);
            res[value['Party Category']]['Pending Orders (Qty-Kgs)'] += Math.round(value['Pending Orders (Qty-Kgs)']);
            res[value['Party Category']]['PO Lost (Kgs)'] += Math.round(value['PO Lost (Kgs)']);
            return res;
        }, {});

        result.sort((a, b) => (a['Sales Return (Value)'] < b['Sales Return (Value)']) ? -1 : ((b['Sales Return (Value)'] > a['Sales Return (Value)']) ? 1 : 0))



        setChartData({
            labels: result.map((data) => data['Id']),
            datasets: [{
                label: "Net Sales",
                data: result.map((data) => data['Sales Value'] - data['Sales Return (Value)']),
                backgroundColor: chartColors[9],
                borderWidth: 0
            },
            {
                label: "Pending Orders",
                data: result.map((data) => data['Pending Orders (Value)']),
                backgroundColor: chartColors[0],
                borderWidth: 0
            },
            {
                label: "PO Loss",
                data: result.map((data) => data['PO Lost (Value)']),
                backgroundColor: chartColors[1],
                borderWidth: 0
            },
            ]
        });

    }, [allData, selectedSite])


    return (
        <Container>
            {allData.length > 0 ?
                <Chart type='bar' options={options} data={chartData}
                />
                :
                <Loading>
                    <HashLoader size={28} />
                </Loading>
            }
        </Container>

    )
}



const ReturnPartyChart = ({ setReturnParties }) => {

    const { allData, chartColors, selectedSite } = useContext(DataContext)


    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        indexAxis: 'y',

        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false
                },
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    crossAlign: 'far',
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return index % 2 === 0 ? this.getLabelForValue(val) : "";
                    },
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    }
                }
            },
            y: {
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        return this.getLabelForValue(val).substring(0, 12) + "...";
                    },

                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    },
                }
            }
        }

    }

    const [chartData, setChartData] = useState({
        datasets: [],
    });

    useEffect(() => {

        var result = [];
        allData.reduce(function (res, value) {
            if (!res[value['Party Description']]) {
                res[value['Party Description']] = { Id: value['Party Description'], 'Sales Return (Value)': 0 };
                result.push(res[value['Party Description']])
            }
            res[value['Party Description']]['Sales Return (Value)'] += Math.round(value['Sales Return (Value)']);
            return res;
        }, {});

        result = result.filter(el => el['Sales Return (Value)'] < 0)
        setReturnParties(result)

        result.sort((a, b) => (a['Sales Return (Value)'] < b['Sales Return (Value)']) ? -1 : ((b['Sales Return (Value)'] > a['Sales Return (Value)']) ? 1 : 0))



        setChartData({
            labels: result.map((data) => data['Id']),
            datasets: [{
                label: "Sales Return (Value)",
                data: result.map((data) => -data['Sales Return (Value)']),
                backgroundColor: chartColors[4],
                borderWidth: 0
            }]
        });

    }, [allData, selectedSite])


    return (
        <Container>
            {allData.length > 0 ?
                <Chart type='bar' options={options} data={chartData}
                />
                :
                <Loading>
                    <HashLoader size={28} />
                </Loading>
            }
        </Container>

    )
}



const PendingOrdersChart = ({ setPendingOrders }) => {

    const { allData, chartColors, selectedSite } = useContext(DataContext)


    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        // indexAxis: 'y',

        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false
                },
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    crossAlign: 'far',
                    callback: function (val, index) {
                        return this.getLabelForValue(val).substring(0, 12) + "...";
                    },
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    }
                }
            },
            y: {
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return index % 2 === 0 ? this.getLabelForValue(val) : "";
                    },

                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    },
                }
            }
        }

    }

    const [chartData, setChartData] = useState({
        datasets: [],
    });

    useEffect(() => {

        var result = [];
        allData.reduce(function (res, value) {
            if (!res[value['Party Description']]) {
                res[value['Party Description']] = { Id: value['Party Description'], 'Pending Orders (Value)': 0 };
                result.push(res[value['Party Description']])
            }
            res[value['Party Description']]['Pending Orders (Value)'] += Math.round(value['Pending Orders (Value)']);
            return res;
        }, {});

        result = result.filter(el => el['Pending Orders (Value)'] > 0)
        setPendingOrders(result)

        result.sort((a, b) => (a['Pending Orders (Value)'] > b['Pending Orders (Value)']) ? -1 : ((b['Pending Orders (Value)'] > a['Pending Orders (Value)']) ? 1 : 0))

        setChartData({
            labels: result.map((data) => data['Id']),
            datasets: [{
                label: "Pending Orders (Value)",
                data: result.map((data) => data['Pending Orders (Value)']),
                backgroundColor:
                    result.map((item, index) =>
                    (index % 10 === 0 ? chartColors[0] :
                        (index % 9 === 0 ? chartColors[1] :
                            (index % 8 === 0 ? chartColors[2] :
                                (index % 7 === 0 ? chartColors[3] :
                                    (index % 6 === 0 ? chartColors[4] :
                                        (index % 5 === 0 ? chartColors[5] :
                                            (index % 4 === 0 ? chartColors[6] :
                                                (index % 3 === 0 ? chartColors[7] :
                                                    (index % 2 === 0 ? chartColors[8] :
                                                        (index % 1 === 0 ? chartColors[9] :
                                                            chartColors[10]
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    ),

                    ),
                borderWidth: 0
            }]
        });

    }, [allData, selectedSite])


    return (
        <Container>
            {allData.length > 0 ?
                <Chart type='bar' options={options} data={chartData}
                />
                :
                <Loading>
                    <HashLoader size={28} />
                </Loading>
            }
        </Container>

    )
}




export {
    SalesvsReturn, SalesvsReturnChart, SalesvsPendingvsPOLoss, SalesvsPendingvsPOLossChart, ReturnPartyChart, PendingOrdersChart
}