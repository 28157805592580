import styled from "styled-components"
import { MdConstruction } from "react-icons/md"

const Container = styled.div`
    height: 100%;
    width: 100%;
`
const Body = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: var(--fsxl);
    overflow-y: scroll;
    gap: 20px;

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: var(--purple);
    }
`

const Text = styled.div`
    font-size: var(--fsxl);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 800;
    color: #6d597a;
`

const Noreport = () => {
    return (
        <Container>
            <Body>
                <MdConstruction size={60} color={"#f9844a"} />
                <Text>
                    Report under construction
                </Text>

            </Body>
        </Container>
    )
}

export default Noreport