import { useEffect } from "react";
import { createContext, useState } from "react";


export const DataContext = createContext()

const AuthContextProvider = (props) => {

    // const url1 = "http://localhost:8080/api/v1/"
    const url1 = "https://safeharvest-391908.as.r.appspot.com/api/v1/"

    // const chartColors = ["#003566", "#f72585", "#a7c957", "#fca311", "#4361ee", "#affc41", "#aaf683", "#7678ed", "#f5d7e3", "#ff9b85", "4cc9f0"]
    // const chartColors = ["#fbf8cc", "#b9fbc0", "#fde4cf", "#98f5e1", "#ffcfd2", "#8eecf5", "#a3c4f3", "#9bf6ff", "#ffc6ff", "#a0c4ff", "4cc9f0"]
    const chartColors = ["#f94144", "#277da1", "#f3722c", "#577590", "#f8961e", "#43aa8b", "#f9844a", "#90be6d", "#f9c74f", "#f2d0a9", "#ff9f1c"]
    const updateFill = "https://python-backend-dot-safeharvest-391908.as.r.appspot.com/fillrate?"
    // const updateFill = "http://127.0.0.1:8080/fillrate?"


    const [sales, setSales] = useState(false)
    const [invoice, setInvoice] = useState(false)
    const [execute, setExecute] = useState(false)


    const [report, setReport] = useState("Fill")

    const [selectedSite, setSelectedSite] = useState("Nil")

    // useEffect(() => {
    //     console.log(selectedSite)
    // }, [selectedSite])

    const [date, setDate] = useState([new Date(new Date().setDate(new Date().getDate() - 10)), new Date()])

    const [allData, setAllData] = useState([])
    const [minDate, setMinDate] = useState(new Date(new Date().setDate(new Date().getDate() - 10)).toISOString().split('T')[0])
    const [maxDate, setMaxDate] = useState(new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0])
    const [mainLoader, setMainLoader] = useState("false")

    useEffect(() => {

        async function updateValue() {
            // console.log("111111", minDate, maxDate)

            if (minDate !== "" && maxDate !== "") {

                setMainLoader("true")
                let response = await fetch(updateFill + "X=" + minDate + "&Y=" + maxDate, {
                    method: 'get',

                })
                const json = await response.text();
                // console.log(json)

                if (json === "success") {
                    // console.log(2222)

                    if (report === "Fill") {
                        // console.log(3333)
                        let response = await fetch(url1 + "dash/fill", {
                            method: 'post',
                        })
                        const json = await response.json();
                        await setAllData(json.data)
                        if (json.data.length > 0) {
                            setMainLoader("false")
                        } else {
                            setMainLoader("nodata")
                        }
                    }

                }
            }
        }

        updateValue()

    }, [minDate, maxDate])


    const [user, setUser] = useState(() => localStorage.getItem("user") || "[]");

    // useEffect(() => {
    //     console.log(user)
    // })
    // const [user, setUser] = useState([])

    // useEffect(() => {
    //     if (localStorage.getItem("user") !== undefined) {
    //         setUser(localStorage.getItem("user"))
    //     } else {
    //         setUser([])
    //     }
    // }, [])


    useEffect(() => {

        async function fetchAPI() {

            setMainLoader("true")
            if (report === "Fill") {
                let response = await fetch(url1 + "dash/fill", {
                    method: 'post',
                })
                const json = await response.json();
                await setAllData(json.data)
                // console.log(json.data.length)
                if (json.data.length > 0) {
                    setMainLoader("false")
                } else {
                    setMainLoader("nodata")
                }
            }

            if (report === "Account") {
                let response = await fetch(url1 + "dash/account", {
                    method: 'post',
                })
                const json = await response.json();
                await setAllData(json.data)
            }

            if (report === "Inv") {
                let response = await fetch(url1 + "dash/inv", {
                    method: 'post',
                })
                const json = await response.json();
                await setAllData(json.data)
            }

        }

        fetchAPI()
    }, [report])


    // useEffect(() => {
    //     console.log(report)
    // })

    ///////////////// Pie Chart Options /////////////////////////

    const options = {
        plugins: {
            legend: {
                display: false,
            }
        },
        indexAxis: 'y',
        scales: {

            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false
                },
                ticks: {
                    display: false,
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return this.getLabelForValue(val).substring(0, 15) + "...";
                    },
                    crossAlign: 'far',
                    font: {
                        family: "Poppins", // Add your font here to change the font of your y axis
                    },

                },
            },
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false
                },

                ticks: {
                    display: false,
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return index % 2 === 0 ? this.getLabelForValue(val) : "";
                    },
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    },
                }
            }
        }
    }





    return (
        <DataContext.Provider value={{ date, setDate, mainLoader, url1, minDate, maxDate, user, setUser, options, chartColors, report, setReport, selectedSite, allData, setAllData, setSelectedSite, sales, setSales, invoice, setInvoice, execute, setExecute, setMinDate, setMaxDate }}>
            {props.children}
        </DataContext.Provider>
    )
}

export default AuthContextProvider;
