import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useContext } from 'react';
import { DataContext } from '../Context/DataContext';
import { styled as style } from '@mui/material/styles';
import styled from 'styled-components';


const Container = styled.div`
    height: 100%;
    width: 100%;
    padding-right: 10px;
    overflow-y: scroll;
    font-size: var(--fsm);

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: var(--purple);
    }
`

const FillBar = styled.div`
    height: 30px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`
const BarBg = styled.div`
    height: 5px;
    width: 90%;
    margin: auto;
    background-color: #dee2e6;
    display: flex;
    align-items: center;
    // justify-content: center;
`
const Bar = styled.div`
    height: 5px;
    width: ${props => props.width}%;
    // width: 90%;
    background-color: blue;
`
const Value = styled.div`
`


const StyledTableCell = style(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(253, 213, 69, 0.5)",
        color: theme.palette.common.black,
        fontSize: '12px',
        fontWeight: "900",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '12px',
    },
}));

const StyledTableRow = style(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: "rgba(255,205,178, 0.3)",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const BasicTable = ({ finalData, selectable }) => {

    const { setSelectedSite } = useContext(DataContext)

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table sx={{
                    minWidth: "100%", fontFamily: 'Manrope',
                }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">Party Cat.</StyledTableCell>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">In Qty</StyledTableCell>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">In Kgs</StyledTableCell>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">In Value</StyledTableCell>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">Loss % (Value)</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {finalData.map((row, value) => (
                            <StyledTableRow onClick={selectable === true ? () => setSelectedSite(row['Id']) : () => { }}
                                key={value}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontFamily: 'Manrope', }}
                            >
                                <StyledTableCell sx={{ fontWeight: "600", backgroundColor: "rgba(255,205,178,0.7)", fontFamily: "Manrope", whiteSpace: "nowrap" }} align="left">{row.Id}</StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: "600", fontFamily: "Manrope" }} align="right">{row['Qty-SO vs Inv']}</StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: "600", fontFamily: "Manrope" }} align="right">{row['Qty in Kgs-SO vs Inv']}</StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: "600", fontFamily: "Manrope" }} align="right">{row['Value-SO vs Inv']}</StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: "600", fontFamily: "Manrope" }} align="right">
                                    <FillBar>
                                        <BarBg>
                                            <Bar width={Math.round((row['Value-SO vs Inv'] / row['SO-Value']) * 100)}>
                                            </Bar>
                                        </BarBg>
                                        <Value>
                                            {Math.round((row['Value-SO vs Inv'] / row['SO-Value']) * 100)}
                                        </Value>
                                    </FillBar>
                                </StyledTableCell>
                            </StyledTableRow >
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container >
    );
}

const AccountTable = ({ finalData, selectable }) => {

    const { setSelectedSite } = useContext(DataContext)

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table sx={{
                    minWidth: "100%", fontFamily: 'Manrope',
                }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">Party Cat.</StyledTableCell>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">Sales Value</StyledTableCell>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">Return Value</StyledTableCell>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">Return % (Value)</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {finalData.map((row, value) => (
                            <StyledTableRow onClick={selectable === true ? () => setSelectedSite(row['Id']) : () => { }}
                                key={value}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontFamily: 'Manrope', }}
                            >
                                <StyledTableCell sx={{ fontWeight: "600", backgroundColor: "rgba(255,205,178,0.7)", fontFamily: "Manrope", whiteSpace: "nowrap" }} align="left">{row.Id}</StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: "600", fontFamily: "Manrope" }} align="right">{row['Sales Value']}</StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: "600", fontFamily: "Manrope" }} align="right">{row['Sales Return (Value)']}</StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: "600", fontFamily: "Manrope" }} align="right">
                                    <FillBar>
                                        <BarBg>
                                            <Bar width={isNaN(Math.round(Math.abs((row['Sales Return (Value)'] / row['Sales Value']) * 100))) ? 0 : Math.round(Math.abs((row['Sales Return (Value)'] / row['Sales Value']) * 100))}>
                                            </Bar>
                                        </BarBg>
                                        <Value>
                                            {isNaN(Math.round(Math.abs((row['Sales Return (Value)'] / row['Sales Value']) * 100))) ? 0 : Math.round(Math.abs((row['Sales Return (Value)'] / row['Sales Value']) * 100))}
                                        </Value>
                                    </FillBar>
                                </StyledTableCell>
                            </StyledTableRow >
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container >
    );
}


const AccountTable1 = ({ finalData, selectable }) => {

    const { setSelectedSite } = useContext(DataContext)

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table sx={{
                    minWidth: "100%", fontFamily: 'Manrope',
                }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">Party Cat.</StyledTableCell>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">Net Sales</StyledTableCell>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">Pending Orders</StyledTableCell>
                            <StyledTableCell sx={{ fontFamily: "Manrope" }} align="center">PO Loss</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {finalData.map((row, value) => (
                            <StyledTableRow onClick={selectable === true ? () => setSelectedSite(row['Id']) : () => { }}
                                key={value}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontFamily: 'Manrope', }}
                            >
                                <StyledTableCell sx={{ fontWeight: "600", backgroundColor: "rgba(255,205,178,0.7)", fontFamily: "Manrope", whiteSpace: "nowrap" }} align="left">{row.Id}</StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: "600", fontFamily: "Manrope" }} align="right">{row['Sales Value'] + row['Sales Return (Value)']}</StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: "600", fontFamily: "Manrope" }} align="right">{row['Pending Orders (Value)']}</StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: "600", fontFamily: "Manrope" }} align="right">{row['PO Lost (Value)']}</StyledTableCell>
                            </StyledTableRow >
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container >
    );
}


export { BasicTable, AccountTable, AccountTable1 }