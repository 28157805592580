// import FormData from "form-data";
import validator from 'validator'


const SendImage = async ({ endpoint, photo, firstCode }) => {

    const formData = new FormData()
    formData.append("imagename", photo)
    formData.append("firstCode", firstCode)
    const response = await fetch(endpoint, {
        method: 'post',
        body: formData
    })

    const json = await response.json()
    return json
}



const FetchData = async ({ endpoint, data, url }) => {
    const response = await fetch(endpoint, {
        method: 'post',

        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data: data })
    })
    const json = await response.json()
    return json
}


const CheckSignUp = async (signUpValue, designation, accessLevel) => {

    if (signUpValue['userName'] === "" || signUpValue['userName'] === undefined) {
        return { error: "field empty", message: "Enter Valid User Name" }
    } else if (!validator.isEmail(signUpValue['emailID'])) {
        return { error: "field empty", message: "Enter Valid Email ID" }
    } else if (designation === "" || designation === undefined) {
        return { error: "field empty", message: "Choose Valid Designation" }
    } else if (accessLevel === "" || accessLevel === undefined) {
        return { error: "field empty", message: "Choose Valid Access Level" }
    } else if (signUpValue['setPassword'] === "" || signUpValue['setPassword'] === undefined || (signUpValue['confirmPassword']).length < 8) {
        return { error: "field empty", message: "Enter 8 digit password" }
    } else if (signUpValue['confirmPassword'] === "" || signUpValue['confirmPassword'] === undefined || (signUpValue['confirmPassword']).length < 8) {
        return { error: "field empty", message: "Enter 8 digit password" }
    } else if (signUpValue['confirmPassword'] !== signUpValue['setPassword']) {
        return { error: "password mismatch", message: "Password Mismatch. Please enter same password" }
    } else {
        return { error: "no error", message: "" }
    }
}

const CheckLogin = async (loginValue) => {

    if (loginValue['userName'] === "" || loginValue['userName'] === undefined) {
        return { error: "field empty", message: "Enter Valid User Name" }
    } else if (loginValue['passWord'] === "" || loginValue['passWord'] === undefined) {
        return { error: "field empty", message: "Enter Valid Password" }
    } else {
        return { error: "no error", message: "" }
    }
}



const CheckForgetPassword = async (password1, password2) => {

    if (password1 === "" || password1 === undefined || (password1.length < 8)) {
        return { error: "field empty", message: "Enter 8 digit password" }
    } else if (password2 === "" || password2 === undefined || (password2.length < 8)) {
        return { error: "field empty", message: "Enter 8 digit password" }
    } else if (password1 !== password2) {
        return { error: "password mismatch", message: "Password Mismatch. Please enter same password" }
    } else {
        return { error: "no error", message: "" }
    }
}

const CheckFirstCodeForm = async (form, finalVendorCode) => {

    if (form['qtyReceived'] === "" || form['qtyReceived'] === undefined) {
        return { error: "field empty", message: "Enter Valid Qty Received" }
    } else if (form['qtySelected'] === "" || form['qtySelected'] === undefined) {
        return { error: "field empty", message: "Enter Valid Qty Selected" }
    } else if (form['qtyRejected'] === "" || form['qtyRejected'] === undefined) {
        return { error: "field empty", message: "Enter Valid Qty Rejected" }
    } else if (form['rtvTracking'] === "" || form['rtvTracking'] === undefined) {
        return { error: "field empty", message: "Enter Valid RTV Tracking" }
    } else if (finalVendorCode === "" || finalVendorCode === undefined) {
        return { error: "field empty", message: "Enter Valid Vendor Code" }
        // } else if (form['qtyReceived'] < form['qtySelected']) {
        //     console.log(form['qtyReceived'] - form['qtySelected'])
        //     return { error: "field empty", message: "Selected should be less than Received" }
        // } else if (form['qtyReceived'] < form['qtyRejected']) {
        //     return { error: "field empty", message: "Rejected should be less than Received" }
        // } else if (form['qtyReceived'] < (form['qtyRejected'] + form['qtySelected'])) {
        //     return { error: "field empty", message: "Selected & Rejected should be less than Received" }
    } else {
        return { error: "no error", message: "" }
    }
}



const CheckForm2 = async (form) => {

    if (form['costPrice'] === "" || form['costPrice'] === undefined) {
        return { error: "field empty", message: "Enter Valid Cost Price" }
    } else if (form['sampleQuantity'] === "" || form['sampleQuantity'] === undefined || form['sampleQuantity'] > 3) {
        return { error: "field empty", message: "Enter Valid Sample Quantity (Max 3)" }
    } else if (form['stock'] === "" || form['stock'] === undefined) {
        return { error: "field empty", message: "Enter Valid Stock" }
    } else if (form['title'] === "" || form['title'] === undefined) {
        return { error: "field empty", message: "Enter Valid Title" }
    } else if (form['description'] === "" || form['description'] === undefined) {
        return { error: "field empty", message: "Enter Valid Description" }
    } else if (form['baseMetal'] === "" || form['baseMetal'] === undefined) {
        return { error: "field empty", message: "Enter Valid Base Metal" }
    } else if (form['baseColor'] === "" || form['baseColor'] === undefined) {
        return { error: "field empty", message: "Enter Valid Base Color" }
    } else if (form['collections'] === "" || form['collections'] === undefined) {
        return { error: "field empty", message: "Enter Valid Collections" }
    } else if (form['productType'] === "" || form['productType'] === undefined) {
        return { error: "field empty", message: "Enter Valid Product Type" }
    } else if (form['subType'] === "" || form['subType'] === undefined) {
        return { error: "field empty", message: "Enter Valid Sub Type" }
    } else if (form['additionalColor'] === "" || form['additionalColor'] === undefined) {
        return { error: "field empty", message: "Enter Valid Additional color" }
    } else {
        return { error: "no error", message: "" }
    }
}



const CheckProductOpsForm = async (form) => {

    if (form['productWeight'] === "" || form['productWeight'] === undefined) {
        return { error: "field empty", message: "Enter Valid Product Weight" }
    } else if (form['productWeight'] === "" || form['productWeight'] === undefined) {
        return { error: "field empty", message: "Enter Valid Product Weight" }
    } else if (form['productLength'] === "" || form['productLength'] === undefined) {
        return { error: "field empty", message: "Enter Valid Product Length" }
    } else if (form['productWidth'] === "" || form['productWidth'] === undefined) {
        return { error: "field empty", message: "Enter Valid Product Width" }
    } else {
        return { error: "no error", message: "" }
    }
}


const CheckPricingForm = async (form) => {

    if (form['mrp'] === "" || form['mrp'] === undefined) {
        return { error: "field empty", message: "Enter Valid MRP" }
    } else if (form['sellingPrice'] === "" || form['sellingPrice'] === undefined) {
        return { error: "field empty", message: "Enter Valid Selling Price" }
    } else if (form['poQty'] === "" || form['poQty'] === undefined) {
        return { error: "field empty", message: "Enter Valid PO Quantity" }
    } else {
        return { error: "no error", message: "" }
    }
}

const CheckBuyerForm = async (form) => {

    if (form['poNumber'] === "" || form['poNumber'] === undefined) {
        return { error: "field empty", message: "Enter Valid PO Number" }
    } else if (form['poQuantity'] === "" || form['poQuantity'] === undefined) {
        return { error: "field empty", message: "Enter Valid PO Quantity" }
    } else {
        return { error: "no error", message: "" }
    }
}

const CheckCatalogForm = async (form) => {

    if (form['catalogID'] === "" || form['catalogID'] === undefined) {
        return { error: "field empty", message: "Enter Valid Catalog ID" }
    } else if (form['fsnID'] === "" || form['fsnID'] === undefined) {
        return { error: "field empty", message: "Enter Valid FSN ID" }
    } else {
        return { error: "no error", message: "" }
    }
}

const CheckPhotoForm = async (form) => {

    if (form['photoLink'] === "" || form['photoLink'] === undefined) {
        return { error: "field empty", message: "Enter Valid Link" }
    } else {
        return { error: "no error", message: "" }
    }
}

const CheckPhotoEditForm = async (form, approve, rejectionDropdown, modelrejectionDropdown) => {

    if (approve === "productapproved") {
        if (form['photoProductLink'] === "" || form['photoProductLink'] === undefined) {
            return { error: "field empty", message: "Enter Valid Link" }
        } else {
            return { error: "no error", message: "" }
        }
    }

    if (approve === "productrejected") {
        if (rejectionDropdown === "") {
            return { error: "field empty", message: "Enter Valid Rejection Reason" }
            // if (form['productRejectionReason'] === "" || form['productRejectionReason'] === undefined) {
            //     return { error: "field empty", message: "Enter Valid Rejection Reason" }
            // } else if (form['productRejectionReason'].length > 30) {
            //     return { error: "field empty", message: "Max length is only 50 characters." }
        } else {
            return { error: "no error", message: "" }
        }
    }

    if (approve === "modelapproved") {
        if (form['photoModelLink'] === "" || form['photoModelLink'] === undefined) {
            return { error: "field empty", message: "Enter Valid Link" }
        } else {
            return { error: "no error", message: "" }
        }
    }

    if (approve === "modelrejected") {
        if (modelrejectionDropdown === "") {
            return { error: "field empty", message: "Enter Valid Rejection Reason" }
            // if (form['modelRejectionReason'] === "" || form['modelRejectionReason'] === undefined) {
            //     return { error: "field empty", message: "Enter Valid Rejection Reason" }
            // } else if (form['modelRejectionReason'].length > 30) {
            //     return { error: "field empty", message: "Max length is only 50 characters." }
        } else {
            return { error: "no error", message: "" }
        }
    }
}


const CheckVideoEditForm = async (form, approve, videoEditDropdown) => {

    if (approve === "productapproved") {
        if (form['photoLink'] === "" || form['photoLink'] === undefined) {
            return { error: "field empty", message: "Enter Valid Link" }
        } else {
            return { error: "no error", message: "" }
        }
    }

    if (approve === "productrejected") {
        if (videoEditDropdown === "") {
            return { error: "field empty", message: "Enter Valid Rejection Reason" }
            // }
            // if (form['rejectionReason'] === "" || form['rejectionReason'] === undefined) {
            //     return { error: "field empty", message: "Enter Valid Rejection Reason" }
            // } else if (form['rejectionReason'].length > 30) {
            //     return { error: "field empty", message: "Max length is only 50 characters." }
        } else {
            return { error: "no error", message: "" }
        }
    }
}


const CheckPhotoQCForm = async (form, approve) => {

    if (approve === "productapproved") {
        if (form['photoLink'].toLowerCase() === "closed") {
            return { error: "no error", message: "" }
        } else {
            return { error: "field empty", message: "Enter the text 'Closed'" }
        }
    }

    if (approve === "productrejected") {
        if (form['rejectionReason'] === "" || form['rejectionReason'] === undefined) {
            return { error: "field empty", message: "Enter Valid Rejection Reason" }
        } else if (form['rejectionReason'].length > 30) {
            return { error: "field empty", message: "Max length is only 50 characters." }
        } else {
            return { error: "no error", message: "" }
        }
    }
}



const CheckModelQCForm = async (form, approve) => {
    if (approve === "productapproved") {
        if ((form['photoProductLink']).toLowerCase() === "closed") {
            return { error: "no error", message: "" }
        } else {
            return { error: "field empty", message: "Enter the text 'Closed'" }
        }
    }

    if (approve === "productrejected") {
        if (form['productRejectionReason'] === "" || form['productRejectionReason'] === undefined) {
            return { error: "field empty", message: "Enter Valid Rejection Reason" }
        } else if (form['productRejectionReason'].length > 30) {
            return { error: "field empty", message: "Max length is only 50 characters." }
        } else {
            return { error: "no error", message: "" }
        }
    }

    if (approve === "modelapproved") {

        if ((form['photoModelLink']).toLowerCase() === "closed") {
            return { error: "no error", message: "" }
        } else {
            return { error: "field empty", message: "Enter the text 'Closed'" }
        }
    }

    if (approve === "modelrejected") {
        if (form['modelRejectionReason'] === "" || form['modelRejectionReason'] === undefined) {
            return { error: "field empty", message: "Enter Valid Rejection Reason" }
        } else if (form['modelRejectionReason'].length > 30) {
            return { error: "field empty", message: "Max length is only 50 characters." }
        } else {
            return { error: "no error", message: "" }
        }
    }
}




export {
    SendImage, FetchData, CheckSignUp, CheckForgetPassword,
    CheckLogin, CheckForm2, CheckProductOpsForm,
    CheckPricingForm, CheckPhotoForm, CheckPhotoEditForm,
    CheckVideoEditForm, CheckPhotoQCForm, CheckModelQCForm,
    CheckBuyerForm, CheckCatalogForm, CheckFirstCodeForm
}