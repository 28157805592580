import styled from 'styled-components'
import { StockvsSalesvsInv } from './InventoryReport'
import { useContext, useEffect } from 'react'
import { DataContext } from '../Context/DataContext'
// import { useEffect } from 'react'
import Switch from '@mui/material/Switch';
import { useState } from 'react'
// import { useEffect } from 'react'
import * as XLSX from "xlsx/xlsx";
import { BsDownload } from "react-icons/bs"

const label = { inputProps: { 'aria-label': 'Switch demo' } };


const Container = styled.div`
    min-height: 100%;
    width: 100%;
    display: flex;
    background-color: var(--beige1);
`
const InnerBody = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;

    font-size: var(--fsm);

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: var(--purple);
    }
`

const KPI = styled.div`
    min-height: 150px;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
`

const ReportHead = styled.div`
    height: 30px;
    width: 100%;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black);
    font-weight: 600;
    font-size: var(--fsl);
    border-radius: 2px;
    letter-spacing: var(--ls);
    text-transform: uppercase;
`

const Units = styled.div`
    min-height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
`

const KPIHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const Unit = styled.div`
    height: 80px;
    width: 150px;
    background-color: ${props => props.bg};
    border-radius: 3px;
    box-shadow: ${props => props.bs};
    display: flex;
    flex-direction: ${props => props.fd};
    align-items: center;
    justify-content: center;
    font-size: var(--fsl);
    font-weight: 500;
    color: var(--black);
    gap: ${props => props.gap};
    letter-spacing: 0.5px;
`
const UnitHead = styled.div`
    font-size: var(--fsm);
    font-weight: 500;
    color: var(--black);
    text-transform: uppercase;
`

const UnitValue = styled.div`
    font-size: var(--fsxl);
    font-weight: 800;
    color: var(--blue);
`

const DateRange = styled.div`
    font-size: var(--fsm);
    letter-spacing: 0px;
    text-transform: capitalize;
    font-weight: 500;
    text-decoration: underline;
    font-style: italic;
`
const Names = styled.div`
    height: 30px;
    width: 100%;
    gap: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black);
    font-weight: 600;
    font-size: var(--fsl);
    border-radius: 2px;
    letter-spacing: var(--ls);
    text-transform: uppercase;
`
const Name = styled.div`
    height: 30px;
    width: max-content;
    gap: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black);
    font-weight: 600;
    font-size: var(--fsl);
    border-radius: 2px;
    letter-spacing: var(--ls);
    text-transform: uppercase;
`
const Download = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const ReportsContainer = styled.div`
    width: 100%;
    height: 300px;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    gap: 20px;
`
const ReportsContainer1 = styled.div`
    width: 100%;
    height: 500px;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    gap: 20px;
`
const Report = styled.div`
    width: ${props => props.width};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`


const InventoryDash = () => {

    const { selectedSite, allData } = useContext(DataContext)
    const [uom, setUOM] = useState(true)
    const [uom1, setUOM1] = useState(true)

    const [salevsreturn, setSalevsReturn] = useState([])
    const [salesvsPendingvsPOLoss, setSalesvsPendingvsPOLoss] = useState([])
    const [returnParties, setReturnParties] = useState([])
    const [pendingOrders, setPendingOrders] = useState([])


    const [chn, setChn] = useState([])
    const [blr, setBlr] = useState([])
    const [hyd, setHyd] = useState([])
    const [del, setDel] = useState([])
    const [mum, setMum] = useState([])
    const [kol, setKol] = useState([])
    const [dewas, setDewas] = useState([])
    const [inv, setInv] = useState([])




    const toggle = () => {
        setUOM(!uom)
        setUOM1(true)
    }

    const toggle1 = () => {
        setUOM1(!uom1)
    }


    const downloadExcel = (data, name) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, name);
    };

    const downloadExcel2 = () => {
        const chn1 = XLSX.utils.json_to_sheet(chn);
        const blr1 = XLSX.utils.json_to_sheet(blr);
        const mum1 = XLSX.utils.json_to_sheet(mum);
        const del1 = XLSX.utils.json_to_sheet(del);
        const hyd1 = XLSX.utils.json_to_sheet(hyd);
        const kol1 = XLSX.utils.json_to_sheet(kol);
        const dewas1 = XLSX.utils.json_to_sheet(dewas);
        const inv1 = XLSX.utils.json_to_sheet(inv);


        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, chn1, "Chennai");
        XLSX.utils.book_append_sheet(workbook, blr1, "Bangalore");
        XLSX.utils.book_append_sheet(workbook, mum1, "Mumbai");
        XLSX.utils.book_append_sheet(workbook, del1, "Delhi");
        XLSX.utils.book_append_sheet(workbook, hyd1, "Hyderabad");
        XLSX.utils.book_append_sheet(workbook, kol1, "Kolkata");
        XLSX.utils.book_append_sheet(workbook, dewas1, "Dewas");
        XLSX.utils.book_append_sheet(workbook, inv1, "Invoice Value");

        XLSX.writeFile(workbook, "FullData.xlsx");
    };



    useEffect(() => {
        var chn = allData.filter(el => el.id == "chn").map(function (val) {
            return val.value
        })
        setChn(chn[0])

        var blr = allData.filter(el => el.id == "blr").map(function (val) {
            return val.value
        })
        setBlr(blr[0])

        var hyd = allData.filter(el => el.id == "hyd").map(function (val) {
            return val.value
        })
        setHyd(hyd[0])

        var mum = allData.filter(el => el.id == "mum").map(function (val) {
            return val.value
        })
        setMum(mum[0])

        var del = allData.filter(el => el.id == "del").map(function (val) {
            return val.value
        })
        setDel(del[0])

        var kol = allData.filter(el => el.id == "kol").map(function (val) {
            return val.value
        })
        setKol(kol[0])

        var dewas = allData.filter(el => el.id == "dewas").map(function (val) {
            return val.value
        })
        setDewas(dewas[0])

        var inv = allData.filter(el => el.id == "inv_value").map(function (val) {
            return val.value
        })
        setInv(inv[0])

    }, [allData])


    useEffect(() => {
        console.log(chn)
    }, [chn, allData])

    return (
        <Container>
            <InnerBody>
                <KPI>
                    <ReportHead>
                        <KPIHead>
                            KPI

                            {allData.length > 0 &&
                                <Download onClick={() => downloadExcel2()} style={{ cursor: "pointer" }}>
                                    <BsDownload />
                                </Download>
                            }
                        </KPIHead>

                    </ReportHead>
                    <Units>
                        <Unit fd={"column"} gap={"0px"}>
                            <div style={{ display: "flex", flexDirection: "column", fontSize: "11px", textTransform: "uppercase", alignItems: "center", justifyContent: "center", color: "var(--blue)", fontWeight: "600" }}>
                                Monthly Sales <Switch {...label} defaultChecked onChange={toggle} /> Inventory
                            </div>
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(142,236,245, 0.4)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Bangalore
                            </UnitHead>

                            <UnitValue>
                                {blr.length > 0 ?
                                    uom === false ?
                                        Math.round((blr.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        Math.round(blr.map(item => item['Total_stock']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(185,251,192, 0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Chennai
                            </UnitHead>

                            <UnitValue>
                                {chn.length > 0 ?
                                    uom === false ?
                                        Math.round((chn.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        Math.round(chn.map(item => item['Total_stock']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(251,248,204,0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Delhi
                            </UnitHead>

                            <UnitValue>
                                {del.length > 0 ?
                                    uom === false ?
                                        Math.round((del.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        Math.round(del.map(item => item['Total_stock']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(251,248,204,0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Hyderabad
                            </UnitHead>

                            <UnitValue>
                                {hyd.length > 0 ?
                                    uom === false ?
                                        Math.round((hyd.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        Math.round(hyd.map(item => item['Total_stock']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>


                        <Unit bs={"var(--bs2)"} bg={"rgba(251,248,204,0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Kolkata
                            </UnitHead>

                            <UnitValue>
                                {kol.length > 0 ?
                                    uom === false ?
                                        Math.round((kol.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        Math.round(kol.map(item => item['Total_stock']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(255,214,165,0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Mumbai
                            </UnitHead>

                            <UnitValue>
                                {mum.length > 0 ?
                                    uom === false ?
                                        Math.round((mum.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        Math.round(mum.map(item => item['Total_stock']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(185,251,192, 0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Dewas
                            </UnitHead>

                            <UnitValue>
                                {dewas.length > 0 ?
                                    uom === false ?
                                        Math.round((dewas.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        Math.round(dewas.map(item => item['Total_stock']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>


                    </Units>

                </KPI>


                {/* //////////////////////// Tables /////////////////////// */}

                <Names>
                    <Name>
                        <span>
                            Stock vs Sales
                        </span>
                    </Name>
                    <Name>
                        <span>
                            Inventory Days
                        </span>
                    </Name>
                </Names>

                <ReportsContainer>
                    <Report width={"100%"}>
                        <StockvsSalesvsInv chn={chn} blr={blr} mum={mum} del={del} hyd={hyd} kol={kol} dewas={dewas} />
                    </Report>
                </ReportsContainer>

                {/* <Name>
                    <span>
                        Sales vs Pending vs PO Loss
                    </span>
                    {salesvsPendingvsPOLoss.length > 0 &&
                        <Download onClick={() => downloadExcel(salesvsPendingvsPOLoss, "sales_pending_poloss.xlsx")} style={{ cursor: "pointer" }}>
                            <BsDownload size={18} />
                        </Download>
                    }
                </Name> */}

                {/* <ReportsContainer>
                    <Report width={"50%"}>
                        <SalesvsPendingvsPOLossChart />
                    </Report>
                    <Report width={"50%"}>
                        <SalesvsPendingvsPOLoss setSalesvsPendingvsPOLoss={setSalesvsPendingvsPOLoss} />
                    </Report>
                </ReportsContainer> */}

                <Names>
                    {/* <Name>
                        <span>
                            Return Parties
                        </span>
                        {returnParties.length > 0 &&
                            <Download onClick={() => downloadExcel(returnParties, "returnParties.xlsx")} style={{ cursor: "pointer" }}>
                                <BsDownload size={18} />
                            </Download>
                        }
                    </Name> */}

                    {/* <Name>
                        {pendingOrders.length > 0 &&
                            <Download onClick={() => downloadExcel(pendingOrders, "pendingorders.xlsx")} style={{ cursor: "pointer" }}>
                                <BsDownload size={18} />
                            </Download>
                        }
                        <span>
                            Pending Orders
                        </span>
                    </Name> */}
                </Names>

                {/* <ReportsContainer1>
                    <Report width={"50%"}>
                        <ReturnPartyChart setReturnParties={setReturnParties} />
                    </Report>
                    <Report width={"50%"}>
                        <PendingOrdersChart setPendingOrders={setPendingOrders} />
                    </Report>
                </ReportsContainer1> */}


            </InnerBody>
        </Container >
    )
}

export default InventoryDash