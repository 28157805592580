import styled from 'styled-components'
import { PendingOrdersChart, ReturnPartyChart, SalesvsPendingvsPOLoss, SalesvsPendingvsPOLossChart, SalesvsReturn, SalesvsReturnChart } from './AccountReport'
import { useContext, useEffect } from 'react'
import { DataContext } from '../Context/DataContext'
// import { useEffect } from 'react'
import Switch from '@mui/material/Switch';
import { useState } from 'react'
// import { useEffect } from 'react'
import * as XLSX from "xlsx/xlsx";
import { BsDownload } from "react-icons/bs"

const label = { inputProps: { 'aria-label': 'Switch demo' } };


const Container = styled.div`
    min-height: 100%;
    width: 100%;
    display: flex;
    background-color: var(--beige1);
`
const InnerBody = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;

    font-size: var(--fsm);

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: var(--purple);
    }
`

const KPI = styled.div`
    min-height: 150px;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
`

const ReportHead = styled.div`
    height: 30px;
    width: 100%;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black);
    font-weight: 600;
    font-size: var(--fsl);
    border-radius: 2px;
    letter-spacing: var(--ls);
    text-transform: uppercase;
`

const Units = styled.div`
    min-height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
`

const KPIHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const Unit = styled.div`
    height: 80px;
    width: 150px;
    background-color: ${props => props.bg};
    border-radius: 3px;
    box-shadow: ${props => props.bs};
    display: flex;
    flex-direction: ${props => props.fd};
    align-items: center;
    justify-content: center;
    font-size: var(--fsl);
    font-weight: 500;
    color: var(--black);
    gap: ${props => props.gap};
    letter-spacing: 0.5px;
`
const UnitHead = styled.div`
    font-size: var(--fsm);
    font-weight: 500;
    color: var(--black);
    text-transform: uppercase;
`

const UnitValue = styled.div`
    font-size: var(--fsxl);
    font-weight: 800;
    color: var(--blue);
`

const DateRange = styled.div`
    font-size: var(--fsm);
    letter-spacing: 0px;
    text-transform: capitalize;
    font-weight: 500;
    text-decoration: underline;
    font-style: italic;
`
const Names = styled.div`
    height: 30px;
    width: 100%;
    gap: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black);
    font-weight: 600;
    font-size: var(--fsl);
    border-radius: 2px;
    letter-spacing: var(--ls);
    text-transform: uppercase;
`
const Name = styled.div`
    height: 30px;
    width: max-content;
    gap: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black);
    font-weight: 600;
    font-size: var(--fsl);
    border-radius: 2px;
    letter-spacing: var(--ls);
    text-transform: uppercase;
`
const Download = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const ReportsContainer = styled.div`
    width: 100%;
    height: 300px;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    gap: 20px;
`
const ReportsContainer1 = styled.div`
    width: 100%;
    height: 500px;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    gap: 20px;
`
const Report = styled.div`
    width: ${props => props.width};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`


const AccountDash = () => {

    const { selectedSite, allData, minDate, maxDate } = useContext(DataContext)
    const [uom, setUOM] = useState(true)
    const [uom1, setUOM1] = useState(true)

    const [salevsreturn, setSalevsReturn] = useState([])
    const [salesvsPendingvsPOLoss, setSalesvsPendingvsPOLoss] = useState([])
    const [returnParties, setReturnParties] = useState([])
    const [pendingOrders, setPendingOrders] = useState([])



    const toggle = () => {
        setUOM(!uom)
        setUOM1(true)
    }

    const toggle1 = () => {
        setUOM1(!uom1)
    }


    const downloadExcel = (data, name) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, name);
    };



    return (
        <Container>
            <InnerBody>
                <KPI>
                    <ReportHead>
                        <KPIHead>
                            KPI

                            {allData.length > 0 &&
                                <Download onClick={() => downloadExcel(allData, "FullData.xlsx")} style={{ cursor: "pointer" }}>
                                    <BsDownload />
                                </Download>
                            }
                        </KPIHead>
                        {minDate !== "" &&
                            <DateRange>
                                Date Range: {minDate.toString().replace('T00:00:00.000Z', '')} to {maxDate.toString().replace('T00:00:00.000Z', '')}
                            </DateRange>
                        }

                    </ReportHead>
                    <Units>
                        <Unit fd={"column"} gap={"0px"}>
                            <div style={{ fontSize: "11px", textTransform: "uppercase", color: "var(--blue)", fontWeight: "600" }}>
                                Qty <Switch {...label} defaultChecked onChange={toggle} /> Value
                            </div>
                            {!uom &&
                                <div style={{ fontSize: "11px", textTransform: "uppercase", color: "var(--blue)", fontWeight: "600" }}>
                                    Kgs <Switch {...label} defaultChecked onChange={toggle1} /> Qty
                                </div>
                            }
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(142,236,245, 0.4)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Sales
                            </UnitHead>

                            <UnitValue>
                                {allData.length > 0 ?
                                    uom === false ?
                                        uom1 === true ?
                                            Math.round(allData.map(item => item['Sales Qty']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            :
                                            Math.round(allData.map(item => item['Sales Qty (Kgs)']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                        :
                                        Math.round(allData.map(item => item['Sales Value']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(185,251,192, 0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Return
                            </UnitHead>

                            <UnitValue>
                                {allData.length > 0 ?
                                    uom === false ?
                                        uom1 === true ?
                                            Math.round(allData.map(item => item['Sales Return Qty']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            :
                                            Math.round(allData.map(item => item['Sales Return (Kgs)']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        Math.round(allData.map(item => item['Sales Return (Value)']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(251,248,204,0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Net Sales
                            </UnitHead>

                            <UnitValue>
                                {allData.length > 0 ?
                                    uom === false ?
                                        uom1 === true ?

                                            (Math.round(allData.map(item => item['Net Sales (Qty)']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            :
                                            (Math.round(allData.map(item => item['Net Sales (Kgs)']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        (Math.round(allData.map(item => item['Net Sales (Value)']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(251,248,204,0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Pending Orders
                            </UnitHead>

                            <UnitValue>
                                {allData.length > 0 ?
                                    uom === false ?
                                        uom1 === true ?

                                            (Math.round(allData.map(item => item['Pending Orders (Qty)']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            :
                                            (Math.round(allData.map(item => item['Pending Orders (Qty-Kgs)']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        (Math.round(allData.map(item => item['Pending Orders (Value)']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>


                        <Unit bs={"var(--bs2)"} bg={"rgba(251,248,204,0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                PO Lost
                            </UnitHead>

                            <UnitValue>
                                {allData.length > 0 ?
                                    uom === false ?
                                        uom1 === true ?

                                            (Math.round(allData.map(item => item['PO Lost (Qty)']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            :
                                            (Math.round(allData.map(item => item['PO Lost (Kgs)']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        (Math.round(allData.map(item => item['PO Lost (Value)']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                        {/* <Unit bs={"var(--bs2)"} bg={"rgba(255,214,165,0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Loss %
                            </UnitHead>

                            <UnitValue>
                                {allData.length > 0 ?
                                    uom === false ?
                                        uom1 === true ?

                                            Math.round((Math.round(allData.map(item => item['PO Lost (Qty)']).reduce((prev, next) => prev + next)) / Math.round(allData.map(item => item['Sales + PO (Qty)']).reduce((prev, next) => prev + next))) * 100)
                                            :
                                            Math.round((Math.round(allData.map(item => item['PO Lost (Kgs)']).reduce((prev, next) => prev + next)) / Math.round(allData.map(item => item['Sales + PO (Qty-Kgs)']).reduce((prev, next) => prev + next))) * 100)
                                        :
                                        Math.round((Math.round(allData.map(item => item['PO Lost (Value)']).reduce((prev, next) => prev + next)) / Math.round(allData.map(item => item['Sales + PO (Value)']).reduce((prev, next) => prev + next))) * 100)

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit> */}

                    </Units>

                </KPI>


                {/* //////////////////////// Tables /////////////////////// */}

                <Name>
                    <span>
                        Sales vs Return
                    </span>
                    {salevsreturn.length > 0 &&
                        <Download onClick={() => downloadExcel(salevsreturn, "sales_vs_return.xlsx")} style={{ cursor: "pointer" }}>
                            <BsDownload size={18} />
                        </Download>
                    }
                </Name>

                <ReportsContainer>
                    <Report width={"50%"}>
                        <SalesvsReturn setSalevsReturn={setSalevsReturn} />
                    </Report>
                    <Report width={"50%"}>
                        <SalesvsReturnChart />
                    </Report>
                </ReportsContainer>

                <Name>
                    <span>
                        Sales vs Pending vs PO Loss
                    </span>
                    {salesvsPendingvsPOLoss.length > 0 &&
                        <Download onClick={() => downloadExcel(salesvsPendingvsPOLoss, "sales_pending_poloss.xlsx")} style={{ cursor: "pointer" }}>
                            <BsDownload size={18} />
                        </Download>
                    }
                </Name>

                <ReportsContainer>
                    <Report width={"50%"}>
                        <SalesvsPendingvsPOLossChart />
                    </Report>
                    <Report width={"50%"}>
                        <SalesvsPendingvsPOLoss setSalesvsPendingvsPOLoss={setSalesvsPendingvsPOLoss} />
                    </Report>
                </ReportsContainer>

                <Names>
                    <Name>
                        <span>
                            Return Parties
                        </span>
                        {returnParties.length > 0 &&
                            <Download onClick={() => downloadExcel(returnParties, "returnParties.xlsx")} style={{ cursor: "pointer" }}>
                                <BsDownload size={18} />
                            </Download>
                        }
                    </Name>

                    <Name>
                        {pendingOrders.length > 0 &&
                            <Download onClick={() => downloadExcel(pendingOrders, "pendingorders.xlsx")} style={{ cursor: "pointer" }}>
                                <BsDownload size={18} />
                            </Download>
                        }
                        <span>
                            Pending Orders
                        </span>
                    </Name>
                </Names>

                <ReportsContainer1>
                    <Report width={"50%"}>
                        <ReturnPartyChart setReturnParties={setReturnParties} />
                    </Report>
                    <Report width={"50%"}>
                        <PendingOrdersChart setPendingOrders={setPendingOrders} />
                    </Report>
                </ReportsContainer1>


            </InnerBody>
        </Container >
    )
}

export default AccountDash