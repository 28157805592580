import { useContext } from "react"
import styled from "styled-components"
import { DataContext } from "../Context/DataContext"
import { useNavigate } from "react-router-dom"


const Container = styled.div`
    height: 50px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    letter-spacing: var(--ls);
    background-color: var(--green);

`

const Logo = styled.div`
    font-weight: 500;
    color: var(--black);
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 800;
`

const Button = styled.div`
    font-size: 12px;
    width: max-content;
    background-color: #2b2b2b;
    color: white;
    padding: 8px 20px;
    border-radius: 3px;
    box-shadow: var(--bs);
    cursor: pointer;
    letter-spacing: 1px;
`


const Navbar = () => {

    const { user } = useContext(DataContext)
    const navigate = useNavigate()


    const handleLogin = () => {
        localStorage.removeItem("user")
        navigate("/")
        window.location.reload()
    }


    return (
        <Container>
            <Logo onClick={() => navigate("/")}>
                Safe Harvest
            </Logo>

            {user === "admin" && <Button onClick={() => handleLogin()}> LOG OUT </Button>}
        </Container>
    )
}

export default Navbar